import { defineStore } from 'pinia'
import Api from "@/services/Api";
import PointsOfSaleServices from "@/services/PointOfSale";
import permissionsMixin from '@/mixins/permissions-mixin.js'
// import { useDiaryStore } from '@/stores/diary'
// import permissionsMixin from "@/mixins/permissions-mixin.js";

export const useReservationTempStore = defineStore({
    id: 'reservation_temp',
    state: () => ({
        title: 'Rezervime',
        reservations_list: [],
        selected_reservation: {},
        show_create_new_reservation: false,
        new_reservation: {
            products: [
                {product_name: '', amount: 1, price: 0}
            ],
            prepayed: 0
        },
        pos_list: [],
        search_val: '',
        current_page: 1,
        per_page: 5,
        show_print_modal: false,

    }),
    getters: {
        current_user() {
            return JSON.parse(localStorage.user);
        },
        filtered_reservations(state) {
            if (state.search_val) {
                return state.reservations_list.filter(reservation => 
                JSON.stringify(reservation).toLowerCase().includes(state.search_val.toLowerCase())
                );
            }
            return state.reservations_list;
        },
        paginated_reservations(state) {
            const start = (state.current_page - 1) * state.per_page;
            const end = state.current_page * state.per_page;
            return state.filtered_reservations.slice(start, end);
        },
        total_pages(state) {
            return Math.ceil(state.filtered_reservations.length / state.per_page);
        }
    },
    actions: {
        async get_data(can_see_all) {
            // console.log('can_see_all', can_see_all)

            Api(true).post("/reservation_temp/get/data", {
                user: this.current_user,
                can_see_all
            }).then(async res => {
                this.reservations_list = res.data.reservations
                this.reservations_list.map(re => {
                    re.products = []
                    res.data.items.map(i => {
                        if (i.reservation_guid == re.guid) {
                            // i.amount = useDiaryStore().amount_filter(i.amount)
                            // i.price = useDiaryStore().amount_filter(i.price)
                            // console.log('Price amount', i.price, i.amount)
                            re.products.push(i)
                        }
                    })
                    return re
                });
                this.pos_list = await PointsOfSaleServices.getPointsOfSale()
            })
        },
        select_reservation(r) {
            this.selected_reservation = { ...r}
            this.show_create_new_reservation = false
        },
        toggle_create_new() {
            this.show_create_new_reservation = !this.show_create_new_reservation
            this.selected_reservation = {}
            this.new_reservation = {
                products: [
                    {product_name: null, amount: 1, price: 0}
                ]
            }
        },
        add_product() {
            if (this.selected_reservation.guid) {
                this.selected_reservation.products.push(
                    { ...{}, product_name: null, amount: null, price: null}
                )
            }
            else {
                this.new_reservation.products.push(
                    { ...{}, product_name: null, amount: null, price: null}
                )
            }
        },
        remove_product(product) {
            // console.log('Delete this prod: ', prod)
            if (confirm("Jeni te sigurt qe doni te fshini kete produkt?")) {
                Api(true).post("/reservation_temp/remove/product", { product })
                .then(res => {
                    if (res.data > 0) {
                        this.selected_reservation.products.filter(p => p.guid != product.guid)
                        this.reservations_list = this.reservations_list.map(r => {
                            if (r.guid == this.selected_reservation.guid) {
                                r.products = [...r.products.filter(i => i.guid != product.guid)]
                            }
                            return r
                        })
                    }
                })
            }
        },
        create_new_reservation() {
            Api(true).post("/reservation_temp/create", {
                new_reservation: this.new_reservation
            }).then(res => {
                if (res.data > 0) {
                    window.location.reload();
                    // this.get_data(this.can_see_all())
                }
            })
        },
        close_reservation(reservation) {
            if (confirm("Jeni te sigurt qe doni konfirmoni kete rezervim?")) {
                Api(true).post("/reservation_temp/close/reservation", {
                    reservation
                }).then(async res => {
                    if (res.data > 0) {

                        window.location.reload();
                        
                        // Backup permission bug
                        // await this.get_data(this.can_see_all())
                        // this.reservations_list.map(r => {
                        //     if (r.guid == this.selected_reservation.guid) {
                        //         this.selected_reservation = {...r}
                        //     }
                        // })
                    }
                })
            }
        },
        save_edit(reservation) {
            Api(true).post("/reservation_temp/save/edit", {
                reservation
            })
            .then(async res => {
                if (res.data > 0) {
                    window.location.reload();
                }
            })
        },
        can_see_all() {
            // Object.assign(this, permissionsMixin);
            permissionsMixin.methods.am_allowed('reservations.can_see_all_reservations')
        },
        setSearchVal(val) {
            this.search_val = val;
            this.current_page = 1;  // Reset to first page on search
        },
        setPage(page){
            if (page > 0 && page <= this.total_pages){
                this.current_page = page;
            }
        },
        single_product_total(product) {
            return product.price * product.amount
        },
        reservation_product_total(reservation) {
            var total = 0
            reservation.products.map(p => {
                total += (p.amount * p.price)
            })
            return total
        },
        to_be_payed(reservation) {
            var to_be_payed = 0
            var total = 0
            reservation.products.map(p => {
                total += (p.amount * p.price)
            })

            to_be_payed = total - (reservation.prepayed > 0 ? reservation.prepayed : 0)
            return to_be_payed
        },
        deliver_reservation(reservation) {
            if (confirm("Jeni te sigurt qe doni ta DOREZONI kete porosi?")) {
                Api(true).post("/reservation_temp/deliver/reservation", { reservation })
                .then(res => {
                    if (res.data > 0) {
                        window.location.reload();
                    }
                })
            }
        },
        toggle_print_modal() {
            this.show_print_modal = !this.show_print_modal
        }
    }
})
