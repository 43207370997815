<template>
    <div>
        <div class="flex">
            <div class=" flex flex-col w-3/5 mb-1">
                <div class="flex justify-between items-center">
                    <span>
                        {{useReservationTempStore().title}}
                        <a @click.prevent="useReservationTempStore().get_data(am_allowed('reservations.can_see_all_reservations'))" href="">
                            <i class="fa-solid fa-arrows-rotate"></i>
                        </a>
                    </span>
                    <div>
                        <input v-model="useReservationTempStore().search_val"
                        @keyup="onSearch"
                        placeholder="Kerko rezervime" type="text" class="input is-small border rounded">
                    </div>
                </div>
            </div>
            <div class="flex items-end ml-auto">
                <span 
                    v-if="am_allowed('reservations.can_create_reservation')" 
                    @click.prevent="useReservationTempStore().toggle_create_new()" class="border rounded cursor-pointer px-2">
                    <i v-if="useReservationTempStore().show_create_new_reservation" class="fa-solid fa-xmark"></i>
                    <i v-else class="fa-solid fa-plus"></i>
                </span>
            </div>
        </div>
        <div class="flex">
            <div class="w-3/5 text-14px">
                <div class="flex flex-col border rounded mb-2">
                    <div class="flex items-center text-slate-400">
                        <div class="w-1/4 border-r px-2">
                            Klienti
                        </div>
                        <div class="w-1/4 border-r px-2">
                            Krijuar nga 
                        </div>
                        <div class="w-1/4 border-r px-2">
                            POS
                        </div>
                        <div class="w-1/4 border-r pl-2 flex items-center">
                            Krijuar me
                            <span class="px-2 ml-auto">#</span>
                        </div>
                    </div>
                </div>

                <div v-for="(reservation, index) in useReservationTempStore().paginated_reservations" :key="index" 
                :class="{'bg-green-200': reservation.status == 1, 'bg-sky-200': reservation.status == 2}"
                    class="flex flex-col border rounded mb-2">
                    <div class="flex items-center">
                        <div class="w-1/4 border-r px-2">
                            <span class="border-r text-14px pr-1">
                                {{reservation.id}}
                            </span>
                            {{reservation.client_name}}
                        </div>
                        <div class="w-1/4 border-r px-2">
                            {{reservation.created_by_name}}
                        </div>
                        <div class="w-1/4 border-r px-2">
                            {{reservation.pos_name}}
                        </div>
                        <div class="w-1/4 border-r pl-2 flex items-center">
                            {{useDiaryStore().formatDate(reservation.created_at)}}
                            <a @click.prevent="useReservationTempStore().select_reservation(reservation)" href="" class="border-l text-slate-400 px-2 ml-auto">
                                <i class="fa-solid fa-right-from-bracket"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="pagination">
                    <button 
                        @click="useReservationTempStore().setPage(1)" 
                        :disabled="useReservationTempStore().current_page === 1"
                        class="px-2 border rounded text-sky-500">
                        <i class="fa-solid fa-angles-left"></i>
                    </button>
                    <button 
                        @click="useReservationTempStore().setPage(useReservationTempStore().current_page - 1)" 
                        :disabled="useReservationTempStore().current_page === 1"
                        class="px-2 border rounded text-sky-500">
                        <i class="fa-solid fa-chevron-left"></i>
                    </button>
                    <span>{{ useReservationTempStore().current_page }} Nga {{ useReservationTempStore().total_pages }}</span>
                    <button
                        @click="useReservationTempStore().setPage(useReservationTempStore().current_page + 1)" 
                        :disabled="useReservationTempStore().current_page === useReservationTempStore().total_pages"
                        class="px-2 border rounded text-sky-500">
                        <i class="fa-solid fa-chevron-right"></i>
                    </button>
                    <button 
                        @click="useReservationTempStore().setPage(useReservationTempStore().total_pages)" 
                        :disabled="useReservationTempStore().current_page === useReservationTempStore().total_pages"
                        class="px-2 border rounded text-sky-500">
                        <i class="fa-solid fa-angles-right"></i>
                    </button>
                </div>
            </div>
            <div class="w-2/5 px-1">
                <!-- Edit form -->
                <div v-if="useReservationTempStore().selected_reservation.guid"
                    id="print-reservation-area">
                    <div>
                        <span class="text-semibold">
                            Rezervimi Nr. {{useReservationTempStore().selected_reservation.id}}
                        </span>
                    </div>
                    <!-- Confirm status -->
                    <div class="w-full flex border rounded my-2"
                        :class="{'bg-amber-100': !useReservationTempStore().selected_reservation.status, 'bg-green-100': useReservationTempStore().selected_reservation.status}">
                        <div class="flex items-center flex-grow p-3 gap-2">
                            <i class="fa-solid fa-circle-info"></i>
                            <span v-if="!useReservationTempStore().selected_reservation.status" class="ml-2">
                                Rezervim i hapur
                            </span>
                            <span v-else class="text-14px">
                                Konfirmuar nga {{useReservationTempStore().selected_reservation.status_updated_by_name}}
                            </span>
                        </div>
                        <div 
                            :class="{'bg-amber-200': !useReservationTempStore().selected_reservation.status && am_allowed('reservations.can_close_reservations'), 'bg-green-200': useReservationTempStore().selected_reservation.status}"
                            class="w-1/3 border-l cursor-pointer flex items-center justify-center ">
                            <div v-if="!useReservationTempStore().selected_reservation.status && am_allowed('reservations.can_close_reservations')"
                                @click="useReservationTempStore().close_reservation(useReservationTempStore().selected_reservation)"
                                >
                                Perfundo
                            </div>
                            <div v-if="useReservationTempStore().selected_reservation.status" 
                                class="flex flex-col  py-1 w-full text-center">
                                <span class="text-slate-400 text-10px"> Konfirmuar me: </span>
                                <span class="text-14px">
                                    {{useDiaryStore().formatDate(useReservationTempStore().selected_reservation.status_updated_at)}}
                                </span>
                                <!-- <span @click.prevent="useReservationTempStore().change_status(useReservationTempStore().selected_reservation, 0)" class="bg-green-500 w-full">
                                    Hap perrseri
                                </span> -->
                            </div>
                            
                        </div>
                    </div>
                    <!-- Deliver reservation status -->
                    <div v-if="useReservationTempStore().selected_reservation.status > 0" class="w-full flex border rounded my-2"
                        :class="{'bg-amber-100': useReservationTempStore().selected_reservation.status == 1 , 'bg-sky-100': useReservationTempStore().selected_reservation.status == 2}">
                        <div class="flex items-center flex-grow p-3 gap-2">
                            <i class="fa-solid fa-circle-info"></i>
                            <span v-if="useReservationTempStore().selected_reservation.status == 1" class="ml-2">
                                Dorezo rezervimin
                            </span>
                            <span v-if="useReservationTempStore().selected_reservation.status == 2" class="text-14px">
                                Dorezuar nga 
                                {{useReservationTempStore().selected_reservation.delivered_by_name}}
                            </span>
                        </div>
                        <div
                            :class="{'bg-amber-200': useReservationTempStore().selected_reservation.status == 1 && am_allowed('reservations.can_close_reservations'), 'bg-sky-200': useReservationTempStore().selected_reservation.status == 2}"
                             class="w-1/3 border-l cursor-pointer flex items-center justify-center ">
                            <div 
                            v-if="useReservationTempStore().selected_reservation.status == 1 && am_allowed('reservations.can_close_reservations')"
                                @click="useReservationTempStore().deliver_reservation(useReservationTempStore().selected_reservation)"
                                >
                                Dorezo
                            </div>
                            <div v-if="useReservationTempStore().selected_reservation.status == 2" 
                                class="flex flex-col  py-1 w-full text-center">
                                <span class="text-slate-400 text-10px"> Dorezuiar me: </span>
                                <span class="text-14px">
                                    {{useDiaryStore().formatDate(useReservationTempStore().selected_reservation.delivered_at)}}
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- Edit form -->
                    <div class="flex flex-col">
                        <div class="flex">
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Emri i klientit</label>
                                <input :disabled="useReservationTempStore().selected_reservation.status > 0 " v-model="useReservationTempStore().selected_reservation.client_name" type="text" class="input is-small border rounded">
                            </div>
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Tel</label>
                                <input :disabled="useReservationTempStore().selected_reservation.status > 0" v-model="useReservationTempStore().selected_reservation.client_tel" type="text" class="input is-small border rounded">
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Adresa</label>
                                <input :disabled="useReservationTempStore().selected_reservation.status > 0" v-model="useReservationTempStore().selected_reservation.address" type="text" class="input is-small border rounded">
                            </div>
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Data e terheqjes</label>
                                <!-- <input :disabled="useReservationTempStore().selected_reservation.status == 1" v-model="useReservationTempStore().selected_reservation.end_date" type="text" class="input is-small border rounded"> -->
                                <Datepicker
                                    v-model="useReservationTempStore().selected_reservation.end_date"
                                    :disabled="useReservationTempStore().selected_reservation.status > 0"
                                    :format="'dd/MM/yyyy HH:mm'"
                                    class="datepicker-wrap-pickup p-0"
                                />
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Pika e shitjes</label>
                                <div class="select is-small">
                                    <select  v-model="useReservationTempStore().selected_reservation.pos_id" :disabled="useReservationTempStore().selected_reservation.status > 0">
                                        <option :value="null">Select dropdown</option>
                                        <option v-for="(pos, index) in useReservationTempStore().pos_list" :key="index" :value="pos.guid">{{pos.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Agjenti i shitjes</label>
                                <input :disabled="useReservationTempStore().selected_reservation.status > 0" v-model="useReservationTempStore().selected_reservation.sales_agent" type="text" class="input is-small border rounded">
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-full flex flex-col px-1 w-1/2">
                                <label class="text-slate-400 text-14px" for="">Emri i operatorit</label>
                                <input :disabled="useReservationTempStore().selected_reservation.status > 0" v-model="useReservationTempStore().selected_reservation.operator_name" type="text" class="input is-small border rounded">
                            </div>
                            <div class="w-full flex flex-col px-1 w-1/2">
                                <label class="text-slate-400 text-14px" for="">Kodi i klientit ne finance</label>
                                <input :disabled="useReservationTempStore().selected_reservation.status > 0" v-model="useReservationTempStore().selected_reservation.finance_code" type="text" class="input is-small border rounded">
                            </div>
                        </div>
                        <!-- Products -->
                        <div class="bg-sky-50 my-2">
                            <div class="flex justify-between px-1 my-2">
                                <div>Produkte</div>
                                <div v-if="!(useReservationTempStore().selected_reservation.status == 1 && !am_allowed('reservations.can_close_reservations') || useReservationTempStore().selected_reservation.status == 2)">
                                    <span @click="useReservationTempStore().add_product()" class="border rounded cursor-pointer px-2">
                                        <i class="fa-solid fa-plus"></i>
                                    </span>
                                </div>
                            </div>
                            <div v-for="(prod, index) in useReservationTempStore().selected_reservation.products" :key="index" class="flex gap-1 px-1 border-b py-1 border-sky-500">
                                <div class="flex-grow flex flex-col">
                                    <label class="text-14px text-slate-400" for="">
                                        Produkti
                                    </label>
                                    <input :disabled="useReservationTempStore().selected_reservation.status == 1 && !am_allowed('reservations.can_close_reservations') || useReservationTempStore().selected_reservation.status == 2" 
                                    v-model="prod.product_name" type="text" class="input is-small border rounded">

                                    <label class="text-14px text-slate-400" for="">
                                        Magazina
                                    </label>
                                    <input :disabled="useReservationTempStore().selected_reservation.status == 1 && !am_allowed('reservations.can_close_reservations') || useReservationTempStore().selected_reservation.status == 2" 
                                    v-model="prod.warehouse_name" type="text" class="input is-small border rounded">

                                </div>
                                <div class="w-1/5 flex flex-col">
                                    <label class="text-14px text-slate-400" for="">
                                        Sasia
                                    </label>
                                    <input :disabled="useReservationTempStore().selected_reservation.status == 1 && !am_allowed('reservations.can_close_reservations') || useReservationTempStore().selected_reservation.status == 2" 
                                    v-model="prod.amount" type="number" class="input is-small border rounded">
                                </div>
                                <div class="w-1/5 flex flex-col">
                                    <label class="text-14px text-slate-400 flex justify-between items-center" for="">
                                        <span> Cmimi </span>
                                        <span 
                                            v-if="!(useReservationTempStore().selected_reservation.status == 1 && !am_allowed('reservations.can_close_reservations') || useReservationTempStore().selected_reservation.status == 2)"
                                             @click.prevent="useReservationTempStore().remove_product(prod)" class="cursor-pointer" href="">
                                            <i  class="fa-solid fa-trash-can text-red-400  text-10px"></i>
                                        </span>
                                    </label>
                                    <input :disabled="useReservationTempStore().selected_reservation.status == 1 && !am_allowed('reservations.can_close_reservations') || useReservationTempStore().selected_reservation.status == 2" 
                                        @keydown.tab="useReservationTempStore().add_product()"
                                        v-model="prod.price"
                                        type="number" class="input is-small border rounded">
                                    <span class="text-8px text-slate-400"><strong class="text-slate-400">TAB</strong> per te shtuar produkt te ri</span>
                                </div>
                                <div class="w-1/5 flex flex-col text-right">
                                    <label  class="text-14px text-slate-400" for="">
                                        Tot. Produkt
                                    </label>
                                    <span>
                                        {{useDiaryStore().amount_filter(useReservationTempStore().single_product_total(prod))}} 
                                        <span class="text-10px text-slate-400"> Leke </span>
                                    </span>
                                </div>
                            </div>
                            <div class="flex flex-col justify-end  ml-auto text-right text-14px">
                                <div class="flex flex-col border-t border-b pr-1">
                                    <span class="text-slate-400 ">
                                        Total i produkteve
                                    </span>
                                    <span>
                                        {{useDiaryStore().amount_filter(useReservationTempStore().reservation_product_total(useReservationTempStore().selected_reservation))}} <span class="text-10px text-slate-400"> Leke </span>
                                    </span>
                                </div>
                                <div class="flex flex-col pr-1">
                                    <label  class="text-14px text-slate-400" for="">
                                        Parapagim
                                    </label>
                                    <input v-model="useReservationTempStore().selected_reservation.prepayed"
                                    :disabled="useReservationTempStore().selected_reservation.status == 2"
                                     type="text" class="input is-small border rounded w-1/5 ml-auto text-right">
                                </div>
                                <div class="flex flex-col border-t mt-1 pr-1">
                                    <span class="text-14px text-slate-400 ">
                                        Total pas parapagimit
                                    </span>
                                    <span>
                                        {{useDiaryStore().amount_filter(useReservationTempStore().to_be_payed(useReservationTempStore().selected_reservation))}} <span class="text-10px text-slate-400"> Leke </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col px-1">
                            <label for="">Shenime nga shitja</label>
                            <textarea v-model="useReservationTempStore().selected_reservation.note" 
                            class="textarea is-small" placeholder=""></textarea>
                        </div>
                        <div v-if="useReservationTempStore().selected_reservation.status >= 1" class="flex flex-col px-1">
                            <label class="text-slate-400 text-14px" for="">
                                Shenime nga Magazina</label>
                            <textarea v-model="useReservationTempStore().selected_reservation.warehouse_notes" class="textarea is-small" placeholder=""></textarea>
                        </div>
                        <div class="flex">
                            <a @click="useReservationTempStore().toggle_print_modal()"
                                class="border rounded bg-orange-600 text-white px-2 mt-2">Print</a>

                            <a 
                                @click.prevent="useReservationTempStore().save_edit(useReservationTempStore().selected_reservation)"
                                class="border rounded bg-sky-600 text-white ml-auto px-2 mt-2">
                                Ruaj ndryshimet
                            </a>
                        </div>
                    </div>
                </div>
                <!-- Create new form -->
                <div v-if="useReservationTempStore().show_create_new_reservation">
                    <p class="font-semibold text-center"> Krijo rezervimin </p>
                    <!-- Create new form -->
                    <div class="flex flex-col">
                        <div class="flex">
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Emri i klientit</label>
                                <input v-model="useReservationTempStore().new_reservation.client_name" type="text" class="input is-small border rounded">
                            </div>
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Tel</label>
                                <input v-model="useReservationTempStore().new_reservation.client_tel" type="text" class="input is-small border rounded">
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Adresa</label>
                                <input v-model="useReservationTempStore().new_reservation.address" type="text" class="input is-small border rounded">
                            </div>
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Data e terheqjes</label>
                                <!-- <input v-model="useReservationTempStore().new_reservation.end_date" type="text" class="input is-small border rounded"> -->
                                <Datepicker
                                    v-model="useReservationTempStore().new_reservation.end_date"
                                    :format="'dd/MM/yyyy HH:mm'"
                                    class="datepicker-wrap-pickup p-0"
                                />
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Pika e shitjes</label>
                                <div class="select is-small">
                                    <select v-model="useReservationTempStore().new_reservation.pos_id">
                                        <option :value="null">Select dropdown</option>
                                        <option v-for="(pos, index) in useReservationTempStore().pos_list" :key="index" :value="pos.guid">{{pos.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="w-1/2 flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Agjenti i shitjes</label>
                                <input v-model="useReservationTempStore().new_reservation.sales_agent" type="text" class="input is-small border rounded">
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-full flex flex-col px-1 w-1/2">
                                <label class="text-slate-400 text-14px" for="">Emri i operatorit</label>
                                <input v-model="useReservationTempStore().new_reservation.operator_name" type="text" class="input is-small border rounded">
                            </div>
                            <div class="w-full flex flex-col px-1">
                                <label class="text-slate-400 text-14px" for="">Kodi i klientit ne finance</label>
                                <input v-model="useReservationTempStore().new_reservation.finance_code" type="text" class="input is-small border rounded">
                            </div>
                        </div>
                        <!-- Products -->
                        <div class="bg-sky-50 my-2">
                            <div class="flex justify-between px-1 my-2">
                                <div>Produkte</div>
                                <div>
                                    <span @click="useReservationTempStore().add_product()" class="border rounded cursor-pointer px-2">
                                        <i class="fa-solid fa-plus"></i>
                                    </span>
                                </div>
                            </div>
                            <div v-for="(prod, index) in useReservationTempStore().new_reservation.products" :key="index" class="flex gap-1 px-1">
                                <div class="flex-grow flex flex-col">
                                    <label  class="text-14px text-slate-400" for="">
                                        Produkti
                                    </label>
                                    <input v-model="prod.product_name" type="text" class="input is-small border rounded">
                                    <label class="text-14px text-slate-400" for="">
                                        Magazina
                                    </label>
                                    <input 
                                    v-model="prod.warehouse_name" type="text" class="input is-small border rounded">
                                </div>
                                <div class="w-1/5 flex flex-col">
                                    <label  class="text-14px text-slate-400" for="">
                                        Sasia
                                    </label>
                                    <input v-model="prod.amount" type="number" class="input is-small border rounded">
                                </div>
                                <div class="w-1/5 flex flex-col">
                                    <label  class="text-14px text-slate-400 flex justify-between items-center" for="">
                                        <span> Cmimi </span>
                                        <i @click.prevent="useReservationTempStore().remove_product(prod)" class="fa-solid fa-trash-can text-red-400 cursor-pointer text-10px"></i>
                                    </label>
                                    <input @keydown.tab="useReservationTempStore().add_product()"
                                        v-model="prod.price"
                                        type="number" class="input is-small border rounded">
                                    <span class="text-8px text-slate-400"><strong class="text-slate-400">TAB</strong> per te shtuar produkt te ri</span>
                                </div>
                                <div class="w-1/5 flex flex-col text-right">
                                    <label  class="text-14px text-slate-400" for="">
                                        Tot. Produkt
                                    </label>
                                    <span>
                                        {{useDiaryStore().amount_filter(useReservationTempStore().single_product_total(prod))}} 
                                        <span class="text-10px text-slate-400"> Leke </span>
                                    </span>
                                </div>
                            </div>
                            <div class="flex flex-col justify-end  ml-auto text-right text-14px">
                                <div class="flex flex-col border-t border-b pr-1">
                                    <span class="text-slate-400 ">
                                        Total i produkteve
                                    </span>
                                    <span>
                                        {{useDiaryStore().amount_filter(useReservationTempStore().reservation_product_total(useReservationTempStore().new_reservation))}} <span class="text-10px text-slate-400"> Leke </span>
                                    </span>
                                </div>
                                <div class="flex flex-col pr-1">
                                    <label  class="text-14px text-slate-400" for="">
                                        Parapagim
                                    </label>
                                    <input v-model="useReservationTempStore().new_reservation.prepayed" type="text" class="input is-small border rounded w-1/5 ml-auto text-right">
                                </div>
                                <div class="flex flex-col border-t mt-1 pr-1">
                                    <span class="text-14px text-slate-400 ">
                                        Total pas parapagimit
                                    </span>
                                    <span>
                                        {{useDiaryStore().amount_filter(useReservationTempStore().to_be_payed(useReservationTempStore().new_reservation))}} <span class="text-10px text-slate-400"> Leke </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col px-1">
                            <label class="text-slate-400 text-14px" for="">Shenime nga shitja</label>
                            <textarea v-model="useReservationTempStore().new_reservation.note" class="textarea is-small" placeholder=""></textarea>
                        </div>
                        
                        <div class="flex">
                            <a @click.prevent="useReservationTempStore().create_new_reservation()" class="border rounded bg-green-600 text-white ml-auto mt-2 px-2">
                                Krijo Rezervim
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="!useReservationTempStore().selected_reservation.guid && !useReservationTempStore().show_create_new_reservation" class="text-center border rounded w-3/5 m-auto py-2">
                    Zgjidhni nje rezervim ose krijoni nje te ri
                </div>
            </div>
        </div>

        <div 
            class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex items-start justify-center text-14px"
            v-if="useReservationTempStore().show_print_modal" @click.self="useReservationTempStore().toggle_print_modal()">
            <div class="p-2 bg-white mt-20 flex-col products-search ">
                <div class="flex">
                    <a @click.prevent="print_reservation()" class="w-full text-center py-2 mb-2 bg-orange-500 text-white text-semibold" href="">Printo</a>
                </div>
                <div id="print-reservation-test" >
                    <div  style="width: 21cm; margin: 0 auto; padding: 20px;">
                        <header  style="text-align: center;">
                            <h1 style="margin-top:5px;">Rezervimi Nr. {{useReservationTempStore().selected_reservation.id}}</h1>
                        </header>
                        <div style="display:flex; justify-content:space-between; border-bottom:solid 1px #ddd; font-size:14px;">
                            <div>
                            <div style="display:flex; margin-bottom:5px;">
                                <b style="min-width:120px; text-align:right;">Emri i klientit:</b> 
                                <span style="margin-left:5px;"> {{useReservationTempStore().selected_reservation.client_name}} </span>
                            </div>
                            <div style="display:flex; margin-bottom:5px;">
                                <b style="min-width:120px; text-align:right;">Tel:</b> 
                                <span style="margin-left:5px;"> {{useReservationTempStore().selected_reservation.client_tel}}</span>
                            </div>
                            <div style="display:flex; margin-bottom:5px;">
                                <b style="min-width:120px; text-align:right;">Adresa:</b> 
                                <span style="margin-left:5px;"> {{useReservationTempStore().selected_reservation.address}}</span>
                            </div>
                            <div style="display:flex; margin-bottom:5px;">
                                <b style="min-width:120px; text-align:right;">Data e terheqjes:</b> 
                                <span style="margin-left:5px;">{{useDiaryStore().formatDate(useReservationTempStore().selected_reservation.end_date)}}</span>
                            </div>
                            <div style="display:flex; margin-bottom:5px;">
                                <b style="min-width:120px; text-align:right; font-size:13px;">Kodi i kli. ne finance:</b> 
                                <span style="margin-left:5px;">{{useReservationTempStore().selected_reservation.finance_code}}</span>
                            </div>

                            </div>
                            <div>
                            <div style="display:flex; margin-bottom:5px;">
                                <b style="min-width:130px; text-align:right;">Krijuar me
                                :</b> 
                                <span style="margin-left:5px;"> 
                                    {{useDiaryStore().formatDate(useReservationTempStore().selected_reservation.created_at)}}
                                    </span>
                            </div>
                            <div style="display:flex; margin-bottom:5px;">
                                <b style="min-width:130px; text-align:right;">Operatori:</b> 
                                <span style="margin-left:5px;"> {{useReservationTempStore().selected_reservation.operator_name}}</span>
                            </div>
                            <div style="display:flex; margin-bottom:5px;">
                                <b style="min-width:130px; text-align:right;">Agjenti i shitjes:</b> 
                                <span style="margin-left:5px;">{{useReservationTempStore().selected_reservation.sales_agent}}</span>
                            </div>
                            <div style="display:flex; margin-bottom:5px;">
                                <b style="min-width:130px; text-align:right;">Pika e shitjes:</b> 
                                <span style="margin-left:5px;">{{useReservationTempStore().selected_reservation.pos_name}}</span>
                            </div>
                            </div>
                        </div>
                        <div  >
                            <h3 style="margin-top: 10px; display:flex; align-items:center;">
                            Statusi i rezervimit

                            <span style="font-size:15px; font-weight:normal; margin-left:10px; display:flex; align-items:center; padding:1px 3px; border:solid 1px #dedede; border-radius:5px;">
                                {{useReservationTempStore().selected_reservation.status == 0 ? 'I hapur' : useReservationTempStore().selected_reservation.status == 1 ? 'I mbyllur' : useReservationTempStore().selected_reservation.status == 2 ? 'I dorezuar' : ''}}
                            </span>
                            </h3>
                            <div style="display: flex; font-size:14px;">
                                <div v-if="useReservationTempStore().selected_reservation.status == 1" style="display: flex; flex-direction: column; width:49%; border:solid 1px #dedede; border-radius:5px; margin-right:2%;">
                                    <span style="text-align:center; border-bottom:solid 1px #dede;margin-bottom:5px;">Statusi: I mbyllur</span>
                                    <span>
                                    <div style="display:flex; margin-bottom:5px;">
                                        <b style="min-width:130px; text-align:right;">Konfirmuar nga:</b> 
                                        <span style="margin-left:5px;">{{useReservationTempStore().selected_reservation.status_updated_by_name}}</span>
                                    </div>
                                    </span>
                                    <span>
                                    <div style="display:flex; margin-bottom:5px;">
                                        <b style="min-width:130px; text-align:right;">Konfirmuar me:</b> 
                                        <span style="margin-left:5px;">{{useDiaryStore().formatDate(useReservationTempStore().selected_reservation.status_updated_at)}}</span>
                                    </div>
                                    </span>
                                </div>
                                <div v-if="useReservationTempStore().selected_reservation.status == 2" style="display: flex; flex-direction: column; width:49%; border:solid 1px #dedede; border-radius:5px; ">
                                    <span style="text-align:center; border-bottom:solid 1px #dede;margin-bottom:5px;">Statusi: I dorezuar</span>
                                    <span>
                                    <div style="display:flex; margin-bottom:5px;">
                                        <b style="min-width:130px; text-align:right;">Konfirmuar nga:</b> 
                                        <span style="margin-left:5px;">{{useReservationTempStore().selected_reservation.delivered_by_name}}</span>
                                    </div>
                                    </span>
                                    <span>
                                    <div style="display:flex; margin-bottom:5px;">
                                        <b style="min-width:130px; text-align:right;">Konfirmuar me:</b> 
                                        <span style="margin-left:5px;"> {{useDiaryStore().formatDate(useReservationTempStore().selected_reservation.delivered_at)}}</span>
                                    </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 20px;">
                        <h3>Produkte</h3>
                        <table  style="width: 100%; border-collapse: collapse; border: 1px solid #ddd;">
                            <thead>
                            <tr>
                                <th style="border: 1px solid #ddd; padding: 5px; text-align: left; font-weight: bold;">Produkt</th>
                                <th style="border: 1px solid #ddd; padding: 5px; text-align: left;text-align:right;">Sasia</th>
                                <th style="border: 1px solid #ddd; padding: 5px; text-align: left;text-align:right;">Cmimi</th>
                                <th style="border: 1px solid #ddd; padding: 5px; text-align: left;text-align:right;">Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(prod, index) in useReservationTempStore().selected_reservation.products" :key="index" style="font-size:14px;">
                                <td style="border: 1px solid #ddd; padding: 5px; text-align: left;">
                                    {{ prod.product_name}} <br>
                                    <span style="font-size: 12px;color: #a1a1a1;"> 
                                        {{prod.warehouse_name}} </span>
                                </td>
                                <td style="border: 1px solid #ddd; padding: 5px; text-align: left;text-align:right;">
                                    {{useDiaryStore().amount_filter(prod.amount)}}</td>
                                <td style="border: 1px solid #ddd; padding: 5px; text-align: left;text-align:right;">
                                    {{useDiaryStore().amount_filter(prod.price)}}</td>
                                <td style="border: 1px solid #ddd; padding: 5px; text-align: left;text-align:right;">
                                    {{useDiaryStore().amount_filter(useReservationTempStore().single_product_total(prod))}} </td>
                            </tr>
                            </tbody>
                        </table>
                        </div>
                        <div  style="margin-top: 20px; display: flex; justify-content: flex-end; flex-direction: column; align-items: flex-end; font-size:14px;">
                        <span style="display: flex;">
                            <b>Total i produkteve: </b> 
                            <span style="width:100px; text-align:right; border-bottom:solid 1px #222;">
                            {{useDiaryStore().amount_filter(useReservationTempStore().reservation_product_total(useReservationTempStore().selected_reservation))}}
                            </span>
                        </span>
                        <span style="display: flex;">
                            <b>Parapaguar:</b> 
                            <span style="width:100px; text-align:right; border-bottom:solid 1px #222;">
                            {{ useDiaryStore().amount_filter(useReservationTempStore().selected_reservation.prepayed)}}
                            </span>
                        </span>
                        <span style="display: flex;">
                            <b>Diferenca:</b> 
                            <span style="width:100px; text-align:right; border-bottom:solid 1px #222;">
                            {{useDiaryStore().amount_filter(useReservationTempStore().to_be_payed(useReservationTempStore().selected_reservation))}}
                            </span>
                        </span>
                        </div>
                        <div  style="margin-top: 20px; display: flex; justify-content:space-between;">
                            <div v-if="useReservationTempStore().selected_reservation.note" style="width:48%;">
                                <h5 style="margin-bottom:5px;">Shënime nga shitja</h5>
                                <div style=" border:solid 1px #dedede; padding:10px; border-radius:5px; margin-right:2%;">
                                    <div style="font-size:14px;">
                                        {{useReservationTempStore().selected_reservation.note}}
                                    </div>
                                </div>
                            </div>
                            <div v-if="useReservationTempStore().selected_reservation.warehouse_notes" style="width:48%;">
                                <h5 style="margin-bottom:5px;">Shënime nga magazina</h5>
                                <div  style="border:solid 1px #dedede; padding:10px; border-radius:5px;">
                                    <div style="font-size:14px;">
                                        {{useReservationTempStore().selected_reservation.warehouse_notes}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
// Stores import
import { useReservationTempStore } from '@/stores/reservation_temp'
import { useDiaryStore } from '@/stores/diary'

</script>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import permissionMixin from '@/mixins/permissions-mixin.js'
export default {
     mixins: [permissionMixin],
    created(){ 
        useReservationTempStore().get_data(
            this.am_allowed('reservations.can_see_all_reservations')
        ) 
    },
    setup() {
        const reservationStore = useReservationTempStore();
        return {
        ...reservationStore,
        onSearch: (event) => {
            reservationStore.setSearchVal(event.target.value);
        },
        };
    },
    methods: {
        print_reservation(){
            const printArea = document.getElementById('print-reservation-test');
            const windowRef = window.open('', 'Print Document', '');
            windowRef.document.write(printArea.outerHTML);
            windowRef.document.close();
            // windowRef.focus();
            windowRef.print();
            // windowRef.close();
        }
    },
}
</script>

<style>
    .datepicker-wrap-pickup > .dp__input{
        padding:0px!important;
    }
    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    .pagination button {
        margin: 0 5px;
    }
    /*@media print {
        
        #print-reservation-area {
            page-break-after: always;
        }
    }*/
</style>